var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.items}}),_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Invoice template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MultiSelectInput',{key:_vm.invoiceTemplateId,attrs:{"required":true,"options":_vm.documentServices.data,"multiple":false,"textLabel":_vm.$t('Invoice template'),"label":"name","trackBy":"id","error":errors.invoiceTemplateId,"moduleName":"documentService"},model:{value:(_vm.invoiceTemplateId),callback:function ($$v) {_vm.invoiceTemplateId=$$v},expression:"invoiceTemplateId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Invoice Correction template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MultiSelectInput',{key:_vm.invoiceCorrectionTemplateId,attrs:{"required":true,"options":_vm.documentServices.data,"multiple":false,"textLabel":_vm.$t('Invoice Correction template'),"label":"name","trackBy":"id","error":errors.invoiceCorrectionTemplateId,"moduleName":"documentService"},model:{value:(_vm.invoiceCorrectionTemplateId),callback:function ($$v) {_vm.invoiceCorrectionTemplateId=$$v},expression:"invoiceCorrectionTemplateId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Invoice Storno template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MultiSelectInput',{key:_vm.invoiceStornoTemplateId,attrs:{"required":true,"options":_vm.documentServices.data,"multiple":false,"textLabel":_vm.$t('Invoice Storno template'),"label":"name","trackBy":"id","error":errors.invoiceStornoTemplateId,"moduleName":"documentService"},model:{value:(_vm.invoiceStornoTemplateId),callback:function ($$v) {_vm.invoiceStornoTemplateId=$$v},expression:"invoiceStornoTemplateId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Payment History template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MultiSelectInput',{key:_vm.paymentHistoryTemplateId,attrs:{"required":true,"options":_vm.documentServices.data,"multiple":false,"textLabel":_vm.$t('Payment History template'),"label":"name","trackBy":"id","error":errors.paymentHistoryTemplateId,"moduleName":"documentService"},model:{value:(_vm.paymentHistoryTemplateId),callback:function ($$v) {_vm.paymentHistoryTemplateId=$$v},expression:"paymentHistoryTemplateId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(_vm.$can(((_vm.$route.meta.permission) + ".save")))?_c('b-button',{staticClass:"d-flex align-items-center gap-1",class:{ 'cursor-not-allowed': _vm.isFormInvalid },attrs:{"variant":"primary"},on:{"click":_vm.createDocument}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Save Template")))])],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }